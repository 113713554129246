<template>
  <v-container style="max-width: 980px; margin: 0 auto;">
    <v-row>
      <v-col sm="12" cols="12" md="4" lg="4">
        <div style="position: sticky; top: 80px;">
          <CardUI class="mb-4 mt-3">
            <template v-slot:body>
              <v-btn
                  color="green"
                  light
                  dark
                  style="border-radius: 10px"
                  class="mx-auto d-flex"
                  elevation="0"
                  to="/projects/create"
                  @mouseover.native="wantCreateProject"
              >
                <v-icon left>mdi-plus</v-icon>
                Создать проект
              </v-btn>
            </template>
          </CardUI>
          <CardUI>
            <template v-slot:footer>

            </template>
            <template v-slot:body>
              <InputUI v-model="filter.title"
                       clearable
                       label="Название проекта"
                       placeholder="Название проекта"
                       v-on:keyup.enter.native="filter_to_query(); filter.page = 1"
              />
              <h4>Кол-во человек в команде</h4>
              <div class="d-flex align-center mt-2">
                <InputUI v-model="filter.user_count.from"
                         :max="filter.user_count.to"
                         placeholder="О"
                         type="number"
                         v-on:keyup.enter="filter_to_query(); filter.page = 1"/>
                <div class="mb-4 mx-2" style="font-size: 16px;">
                  —
                </div>
                <InputUI v-model="filter.user_count.to"
                         :min="filter.user_count.from"
                         placeholder="5"
                         type="number"
                         v-on:keyup.enter="filter_to_query(); filter.page = 1"
                />
              </div>
              <h4>Дата создания</h4>
              <DatePickerUIDecorator>
                <DatePicker :range=true :show-second="false"
                            :value="[filter.created_at.from, filter.created_at.to]"
                            class="mt-2"
                            format="DD.MM.YY" input-class="date-input"
                            placeholder="Дата создания"
                            type="date" value-type="YYYY-MM-DD"
                            @input="filter.created_at.from =  $event[0]; filter.created_at.to =  $event[1];"></DatePicker>
              </DatePickerUIDecorator>
              <v-select v-model="filter.project_type_ids" :items="$store.state.projects.project_types"
                        class="mt-4"
                        color="primary"
                        dense
                        item-text="name"
                        item-value="id"
                        label="Тип проекта"
                        multiple
                        no-data-text="Нет типов"
                        outlined
                        placeholder="Типы"
                        v-on:keyup.enter="filter_to_query(); filter.page = 1"
              ></v-select>

              <v-checkbox v-if="$store.state.user.currentUser.id > 0"
                          v-model="filter.my"
                          dense
                          label="Отобразить только мои проекты"
              ></v-checkbox>
              <v-checkbox v-if="$store.getters.checkPermission('can_manage_projects')"
                          v-model="filter.approved"
                          dense
                          label="На модерации"
              ></v-checkbox>

              <v-checkbox
                  dense
                  v-model="filter.is_archived"
                  label="Показать только архивные"
              ></v-checkbox>

              <v-checkbox
                  dense
                  v-model="filter.with_vacancies"
                  label="Показать только с вакансиями"
              ></v-checkbox>
              <ButtonUI style="position: relative; text-align: left; font-size: 16px" :loading="loading"
                        @click="filter_to_query(); filter.page = 1; search(true);">
                Найти проекты <span style="position: absolute; top: 50%; right: 1em; font-weight: 300; transform: translateY(-50%)">   {{ $store.state.projects.projects.total }}</span>

              </ButtonUI>
            </template>

          </CardUI>
        </div>

      </v-col>
      <v-col sm="12" md="8" lg="8">
        <v-row v-if="loading">
          <v-col v-for="i in 8" :key="i" cols="12" sm="12" lg="12">
            <v-skeleton-loader style="border-radius: 20px; overflow: hidden"
                class="mx-auto mt-2"
                type="image, article, list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <template v-else>
          <div class="mt-4" v-if="projects && projects.length === 0">
            <v-alert
                text
                type="info"
            >
              <v-row align="center">
                <v-col class="grow"> Проектов подходящих под ваши фильтры не нашлось.
                </v-col>
                <v-col class="shrink">
                  <v-btn rounded color="primary" href="/#/projects">Сбросить фильтры</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </div>
          <BackendBanner style="margin-bottom: 1em" code="opd_banner"/>
          <ProjectListItemComponent class="mt-3" v-for="(project) in projects.slice(0, 2)"
                                    :key="project.id" :project="project"/>
          <TelegramBanner style="margin-top: 1em; margin-bottom: 1em"/>
          <ProjectListItemComponent class="mt-3" v-for="(project) in projects.slice(2)"
                                    :key="project.id" :project="project"/>
        </template>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <div ref="page_end">
        </div>
        <ProjectListPagination :current_page="filter.page" :last_page="$store.state.projects.projects.last_page"
                               @new_page="filter.page=$event; filter_to_query(); search();"/>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>
import ProjectListPagination from "@/components/Projects/ProjectListComponent/ProjectListPagination.vue";
import analytics from "@/api/analytics.js";
import ProjectListItemComponent from "@/components/Projects/ProjectListItemComponent.vue";
import CardUI from "@/components/UI/CardUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator.vue";
import DatePicker from "vue2-datepicker";
import _ from "lodash"
import BackendBanner from "@/components/Utility/BackendBanner";
import TelegramBanner from "@/components/TelegramBanner";
import ButtonUI from "@/components/UI/ButtonUI";

export default {
  name: "ProjectsListComponent",
  components: {
    ButtonUI,
    DatePicker,
    DatePickerUIDecorator,
    InputUI,
    CardUI,
    ProjectListPagination,
    ProjectListItemComponent,
    BackendBanner, TelegramBanner
  },
  props: ['my'],
  data() {
    return {
      lazy_load: false,
      isActive: true,
      loading: true,
      userProject: [],
      filter: {
        page: 1,
        title: "",
        project_type_ids: [],
        my: 1,
        approved: 0,
        is_archived: 0,
        with_vacancies: 0,
        user_count: {
          from: 0,
          to: 5
        },
        created_at: {
          from: null,
          to: null
        }
      },
      cancelSearch: window.axios.CancelToken.source()
    }
  },
  watch: {
    // filter: {
    //   handler() {
    //     this.filter_to_query();
    //   },
    //   deep: true
    // },
    $route() {
      this.query_to_filter()
      if (this.my) {
        this.filter.my = true;

      }
      if (this.filter.page === 1) {
        this.search();
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }

      if (this.my) {
        this.filter.my = true;
      }
    }
  },
  computed: {
    filter_obj() {
      let isAuth = this.$store.state.user.currentUser.id > 0;
      let filter = {page: this.filter.page, perPage: 9};
      if (this.filter.my && isAuth) filter.by_user = this.$store.state.user.currentUser.id
      if (this.filter.title.length > 2) filter.title = this.filter.title;
      if (this.filter.project_type_ids.length > 0) filter.project_type_ids = this.filter.project_type_ids.join(",");
      if (this.filter.approved) filter.approved = this.filter.approved;
      if (this.filter.is_archived) filter.is_archived = this.filter.is_archived;
      if (this.filter.with_vacancies) filter.with_vacancies = this.filter.with_vacancies ? 1 : 0;
      if (this.filter.user_count) filter.user_count = this.filter.user_count.from + '-' + this.filter.user_count.to;
      if (this.filter.created_at && this.filter.created_at.from && this.filter.created_at.to) filter.created_at = this.filter.created_at.from + '~' + this.filter.created_at.to;
      return filter;
    },
    projects() {
      return this.$store.state.projects.projects.data;
    }
  },
  methods: {
    filter_to_query() {
      let query =
          {
            my: this.filter.my ? 1 : 0,
            page: this.filter.page,
            title: this.filter.title,
            approved: this.filter.approved ? 0 : 1,
            is_archived: this.filter.is_archived ? 1 : 0,
            with_vacancies: this.filter.with_vacancies ? 1 : 0,
            project_type_ids: this.filter.project_type_ids.join(","),
            user_count: this.filter.user_count.from + '~' + this.filter.user_count.to,
          }
      if (this.filter.created_at.from && this.filter.created_at.to) {
        query.created_at = this.filter.created_at.from + '~' + this.filter.created_at.to;
      }
      this.$router.push({
        name: 'projects', query: query

      }).catch(() => {
      });
    },
    query_to_filter() {
      this.filter.title = this.$route.query.title ? this.$route.query.title : ""
      this.filter.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      this.filter.my = this.$route.query.my ? parseInt(this.$route.query.my) : 0;
      this.filter.approved = this.$route.query.approved == 0 ? true : false;
      this.filter.is_archived = this.$route.query.is_archived == 1 ? true : false;
      this.filter.with_vacancies = this.$route.query.with_vacancies == 1 ? true : false;
      this.filter.project_type_ids = this.$route.query.project_type_ids ? this.$route.query.project_type_ids.split(",").map((el) => parseInt(el)) : [];
      this.filter.user_count.from = this.$route.query.user_count ? this.$route.query.user_count.split("~")[0] : 0
      this.filter.user_count.to = this.$route.query.user_count ? this.$route.query.user_count.split("~")[1] : 10
      if (this.$route.query.created_at && this.$route.query.created_at.split('~').length === 2) {
        this.filter.created_at.to = this.$route.query.created_at ? this.$route.query.created_at.split("~")[1] : this.$moment.utc().local().format('YYYY-MM-DD')
        this.filter.created_at.from = this.$route.query.created_at ? this.$route.query.created_at.split("~")[0] : this.$moment.utc().local().subtract(12, 'month').format('YYYY-MM-DD')
      }
    },
    search(from_button = false) {
      this.loading = true;
      // eslint-disable-next-line no-console
      console.log(this.cancelSearch.token);
      this.$store.dispatch('getProjects', {...this.filter_obj, per_page: 10}).then(() => {

      }).then(() => {
        this.loading = false;
        if (from_button) {
          setTimeout(() => this.$refs.results.scrollIntoView({behavior: 'smooth'}), 1);
        }
      })
    },
    onPageEnd() {
      if (this.$store.state.projects.projects.current_page < this.$store.state.projects.projects.last_page && this.isElementInViewport(this.$refs.page_end) && !this.lazy_load) {
        // eslint-disable-next-line no-console
        console.log('page')
        this.lazy_load = true;
        this.filter.page++;
        window.history.scrollRestoration = "manual"
        this.$store.dispatch('getLazyProjects', {...this.filter_obj, per_page: 10}).then(() => {
          this.lazy_load = false;
          this.$router.replace({query: {...this.$route.query, page: this.filter.page}})
        })
      }
    },
    isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      )
    },

    wantCreateProject() {
      analytics.event({event: 'HOVER_PROJECT_CREATE_BUTTON', details: {}})
    },


  },
  mounted() {
    this.$emit('changeTitle', 'Проекты');
    this.query_to_filter();
    if (this.my) {
      this.filter.my = true;
    }
    this.search();
    Promise.all([
      this.$store.dispatch('getEmployees'),
      this.$store.dispatch('getProjectTypes')]).then(() => {
      this.filter.type = this.$store.state.projects.project_types.map(el => el.id)
    }).then(() => {
      this.loading = false;
      this.$store.dispatch('hideChat');
      analytics.openProjectsPageEvent(this.$store.state.user.currentUser.id ? this.$store.state.user.currentUser.id : 0)

    });

    window.onscroll = _.throttle(this.onPageEnd, 1000)

  },
  beforeDestroy() {
    window.onscroll = undefined
  }
}
</script>

<style scoped>

</style>
